<template>
  <div>
    <Testing class="ccmain" />
  </div>
</template>

<script>
import Testing from "@/components/detection/testing";
export default {
  created() {},
  components: {
    Testing
  },
  methods: {}
};
</script>

<style scoped>
.title {
  display: flex;
  background: #228ef7;
}
.lefticon {
  line-height: 0.8rem;
  padding: 0 0.3rem;
  color: #fff;
  font-size: 0.4rem;
}
.title p {
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  flex: 0.85;
}
.ccmain {
  height: 100%;
  background: #fff;
}
</style>