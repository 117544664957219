<template>
  <div>
    <div>
      <div class="title">
        <van-icon name="arrow-left" class="lefticon" @click="goBack()" />
        <p>{{msgs.banben}}</p>
      </div>
      <div class="page-role">
        <div class="pxui-area">
          <h1>
            <strong class="pxui-color-red">{{msgs.banben}}</strong>
            <span>{{msgs.price}}元{{msgs.pricetype | filterPrice}}</span>
          </h1>
          <p v-html="msgs.desc"></p>
        </div>
      </div>
    </div>

    <div class="paper-information">
      <p>
        <span>论文标题：</span>
        <input type="text" name="title" placeholder="30字以内" v-model="title" />
      </p>
      <p>
        <span>作者姓名：</span>
        <input type="text" name="author" placeholder="姓名将出现在检测报告中" v-model="author" />
      </p>
      <p v-if="this.msgs.id==15">
        <span>发表时间：</span>
        <input type="text" placeholder="论文发表时间" v-model="endDate" @click="selectDate" />
      </p>
      <van-popup v-model="show" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="sureDate"
          @cancel="noDate"
        />
      </van-popup>
      <!-- 上传 -->

      <div class="uploads">
        <ul style="margin-bottom: 0.7rem;display:flex">
          <li
            v-for="(item,index) in uploadmode"
            :key="item.id"
            :class="current==index?'active':''"
            @click="changeuploads(item.id)"
          >{{item.name}}</li>
        </ul>
        <div class="wend" v-if="current==1">
          <div class="wend_name">{{uploadname}}</div>
          <van-uploader accept=".docx, .doc, .txt" :after-read="afterRead">
            <van-button style="height:1rem;background:#228ef7;border:none;color:#fff;">上传文件</van-button>
          </van-uploader>
        </div>
        <textarea
          id="context"
          placeholder="输入论文内容不少于1000字"
          v-if="current==0"
          class="wend-text"
          v-model="content"
        ></textarea>
        <p class="wjgs" v-if="current==1">
          支持文档格式：(
          <span>docx,txt</span>)
        </p>
        <button id="subbutt" @click="toPay(current)" type="button">下一步</button>
        <!-- <button @click="hahax">222</button> -->
        <div style="height:3rem;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      msgs: {},
      current: 0,
      uploadmode: [
        { id: 0, name: "粘贴文本" },
        { id: 1, name: "上传文件" },
      ],
      title: "",
      author: "",
      uploadname: "",
      file: {},
      files: "",
      content: "",
      formData: {},
      payMsg: {},
      file_id: "",
      show: false,
      minDate: new Date(2000, 0, 1),
      currentDate: new Date(),
      endDate: "",
    };
  },
  computed: {
    maxDate() {
      var date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth();
      let day = date.getDate();
      return new Date(year, month, day);
    },
  },
  created() {
    this.msgs = this.$route.query;
  },
  filters: {
    filterPrice: function (value) {
      if (value == 1) {
        value = "/万字";
        return value;
      } else if (value == 2) {
        value = "/篇";
        return value;
      } else if (value == 0) {
        value = "/千字";
        return value;
      }
    },
  },
  methods: {
    selectDate() {
      this.show = true;
    },
    sureDate(e) {
      let currentMonth = e.getMonth() + 1;
      if (e.getMonth() + 1 < 10) {
        currentMonth = "0" + currentMonth;
      }
      let currentDay = e.getDate();
      if (e.getDate() < 10) {
        currentDay = "0" + currentDay;
      }
      let timer = e.getFullYear() + "-" + currentMonth + "-" + currentDay + " ";
      this.endDate = timer;
      this.show = false;
    },
    noDate() {
      this.show = false;
    },
    goBack() {
      this.$router.back();
    },
    changeuploads(id) {
      this.current = id;
    },
    toPay(id) {
      if (id === 0) {
        if (this.content.length < 1000) {
          Toast.fail("字数不能小于1000字");
          return;
        }
        this.$http
          .getTopaste({
            cid: this.msgs.id,
            title: this.title,
            writer: this.author,
            content: this.content,
            from: "wf-h5",
            type: "content",
            publishdate: this.endDate,
          })
          .then((res) => {
            this.payMsg = res.data;
            this.$router.push({
              path: "/pay",
              query: {
                id: this.payMsg.id,
                title: this.payMsg.title,
                writer: this.payMsg.writer,
                words: this.payMsg.words,
                price: this.payMsg.price,
                orderid: this.payMsg.orderid,
              },
            });
          });
      } else {
        if (!this.title || !this.author || !this.file_id) {
          Toast.fail("请填写完整内容");
          return;
        }
        this.$http
          .getToUpload({
            cid: this.msgs.id,
            title: this.title,
            writer: this.author,
            content: "",
            file_id: this.file_id,
            from: "wf-h5",
            type: "file",
            publishdate: this.endDate,
          })
          .then((res) => {
            this.payMsg = res.data;
            this.$router.push({
              path: "/pay",
              query: {
                id: this.payMsg.id,
                title: this.payMsg.title,
                writer: this.payMsg.writer,
                words: this.payMsg.words,
                price: this.payMsg.price,
                orderid: this.payMsg.orderid,
              },
            });
          })
          .catch((err) => {
            Toast.fail("提交失败");
          });
      }
    },
    afterRead(file) {
      var file = file.file;
      console.log(file, 323);
      this.formData = new FormData();
      this.formData.append("file", file);
      this.uploadname = file.name;
      this.$http
        .getFileId(this.formData)
        .then((res) => {
          this.file_id = res.data.id;
          Toast("上传成功");
        })
        .catch((err) => {
          Toast("上传失败，仅支持docx和txt格式");
        });
    },
  },
};
</script>

<style scoped>
.title {
  display: flex;
  background: #228ef7;
}
.pxui-color-red {
  color: #fb4e3a;
  font-size: 0.5rem;
}
.pxui-area h1 span {
  font-size: 0.3rem;
  color: #575757;
}
.pxui-area {
  padding: 0.3rem;
  background: #fff;
}
.pxui-area p {
  text-indent: 2em;
  font-size: 0.3rem;
  line-height: 0.5rem;
  font-size: 0.33rem;
  color: #575757;
}
.lefticon {
  line-height: 0.8rem;
  padding: 0 0.3rem;
  color: #fff;
  font-size: 0.4rem;
}
.title p {
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  flex: 0.85;
}
.pxui-area h1 {
  line-height: 0.8rem;
}
.paper-information {
  margin: 0 0.3rem;
  margin-top: 0.3rem;
}
.paper-information > p {
  margin-bottom: 0.3rem;
  border: 1px solid #e3e3e3;
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  display: flex;
}
.paper-information > p > span {
  color: #333;
  font-size: 0.3rem;
  display: block;
  width: 30%;
  padding-left: 0.25rem;
  line-height: 46px;
}
.paper-information > p > input {
  width: 70%;
  vertical-align: top;
  font-size: 0.3rem;
  border: none;
}
.uploads > ul {
  margin-top: 0.5rem;
  border-bottom: 1px solid #e3e3e3;
}

.uploads > ul > li.active {
  border: 1px solid #e3e3e3;
  border-bottom: none;
  color: #1a1a1a;
  font-weight: bold;
}
.uploads > ul > li {
  padding: 0 0.4rem;
  border: 1px solid #fff;
  margin-bottom: -0.02rem;
  font-size: 0.3rem;
  line-height: 0.8rem;
  height: 0.8rem;
  background-color: #fff;
  border-bottom: 1px solid #e3e3e3;
  color: #919191;
}
.wend {
  display: flex;
  margin-bottom: 0.3rem;
}
.wend_name {
  width: 70%;
  height: 1rem;
  border: 1px solid #ccc;
  line-height: 1rem;
  font-size: 0.29rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wjgs {
  color: #939393;
  font-size: 0.25rem;
  margin: 0.7rem 0 0.7rem 0;
}
.wend-text {
  width: 98%;
  height: 3rem;
  padding: 0.3rem;
  box-sizing: border-box;
  margin-bottom: 0.3rem;
}
.uploads > button {
  border: 0 none;
  font-size: 0.3rem;
  color: #fff;
  background-color: #228ef7;
  height: 1rem;
  line-height: 1rem;
  padding: 0 1rem;
  font-weight: bold;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  display: block;
  margin: 0 auto;
}
</style>